/*Utilidades*/
.oculta{font-size: 0px; margin: 0px; padding: 0px;}
.logo{max-width: 200px;}
.img-slide{width: 100%;
			border: 0;}
 nav ul li a{
	padding: 10px;
}
.pading{
	padding-top: 65px;
}
.full{
	width: 100%;
	height: 600px;
}
.clean{
	clear: both;
}
.branco{
	background-color: #f8f8f8;
}
.sombra{
	text-shadow: black 0.1em 0.1em 0.2em;
}

.logo_apresentacao{
	width: 500px; height: 350px;
}

body{
 	background-color: #f8f8f8;
}


nav ul li  a:hover, nav ul li  a:focus {
    color: #212121  !important;
    background-color: #eeeeee  !important;
}

.navbar-default {
	background-color: #f5f5f5 !important;

}

.navbar .divider-vertical {
height: 40px;
margin: 0 9px;
border-left: 1px solid #f2f2f2;
border-right: 1px solid #ffffff;
}

/*************************
*******feature******
**************************/
#feature {
	padding-top:90px;
	background:#fff;
	padding-bottom:50px;
}

.hi-icon-effect {
	padding-bottom:30px;
	padding-left:10px;
	padding-right:10px;
}

.hi-icon-effect:hover {
	border-radius:6px;
	background:#f4f4f4;
	-webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition:  color 300ms, background-color 300ms;
  transition:  color 300ms, background-color 300ms;
}

.hi-icon-effect i.fa:hover {
	background:#fff;
	color: #0BA9F9;
	-webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition:  color 300ms, background-color 300ms;
  transition:  color 300ms, background-color 300ms;
}

.text-center i {
	font-size:30px;
	margin-top:50px;
	margin-bottom:30px;
	color:#fff;
	padding:50px;
	background:#0BA9F9;
	border-radius:50%;
}
.text-center h2 {
	margin-top:20px;
	margin-bottom:20px;
}

.text-center h3 {
	font-size:35px;
	margin-top:50px;
	margin-bottom:20px;
	color:#111;
}

.text-center p {
	margin-bottom:30px;
}

/*************************
*******services******
**************************/
#gallery {
	padding-top:70px;
	padding-bottom:70px;
	background:#f4f4f4;
}
#gallery img {
	border-radius:8px;
}

.gallery {
	margin-top:60px;
	margin-bottom:30px;
}

#gallery .text-center {
	padding-top:20px;
	margin-bottom:50px;
	padding-bottom:20px;
}

figure.effect-chico img {
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.12);
	transform: scale(1.12);
}

figure.effect-chico:hover img {
	opacity: 0.5;
	-webkit-transform: scale(1);
	transform: scale(1);
}

#our-team {
	padding-top:70px;
	padding-bottom:70px;
	background:#f4f4f4;
}

#our-team h4 {
	color:#0BA9F9;
}

#our-team .text-center {
	padding-top:20px;
	margin-bottom:30px;
	padding-bottom:10px;
}

#our-team .team img {
	border-radius:8px;
}

/*************************
*******footer******
**************************/
footer {
	
	padding-bottom:20px;
}

#contact {

	padding-top:70px;
	padding-bottom:80px;
}

#contact .text-center {
	padding-top:20px;
	margin-bottom:30px;
	padding-bottom:10px;
}

#contact ul {
	list-style:none;
	margin-left:-40px;
	font-size:13px;
}

#contact h2 {
	color:#000;
	margin-bottom:30px;
	font-size:25px;
}
.form-inline {
	margin-top:15px;
	margin-bottom:15px;	
}

.form-inline .form-group input[type="name"]{
	margin-top:10px;
	border-radius:4px;
	border-radius:6px;
}

.form-inline .form-group input[type="email"]{
	margin-top:10px;
	border-radius:6px;
	padding:5px 20px;
}

.sub-footer form {
	margin-top:60px;
	margin-bottom:30px;
}

.sub-footer .form-inline .form-group button[type="purchase"] {
	padding:20px 90px;
	font-size:25px;
}

.sub-footer .form-inline .form-group button[type="purchase"]:hover {
	background:#fff;
	color:#0BA9F9;
	-webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition:  color 300ms, background-color 300ms;
  transition:  color 300ms, background-color 300ms;
}

.sub-footer .form-inline .form-group button[type="subscribe"] {
	padding:20px 90px;
	font-size:25px;
	background:#fff;
	color:#0BA9F9;
	border-color: #0BA9F9;
}

.sub-footer .form-inline .form-group button[type="subscribe"]:hover {
	background:#0BA9F9;
	color:#fff;
	-webkit-transition: color 300ms, background-color 300ms;
  -moz-transition: color 300ms, background-color 300ms;
  -o-transition:  color 300ms, background-color 300ms;
  transition:  color 300ms, background-color 300ms;
}

ul.social-network {
	list-style:none;
	margin-top:20px;	
	padding:10px;
	transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.social-icon {
	text-align:center;
	margin-bottom:30px;
}

ul.social-network li {
	display:inline;
	margin: 0 18px;	
	font-size:25px;	
}

ul.social-network li a {	
	color:#d0d0d0;
	text-align:center;
}
.social-network li:hover .fa-twitter {
  color: #00abf0;
}
.social-network li:hover .fa-facebook {
  color: #39579a;
}
.social-network li:hover .fa-google-plus {
  color: #eb8984;
}
.social-network li:hover .fa-pinterest {
  color: #c9171e; 
}
.social-network li:hover .fa-linkedin {
  color: #0077b5; 
}
.social-network li:hover .fa-youtube-play {
  color: #C84941; 
}
.social-network li:hover .fa-dribbble {
  color: #EA4C89 ; 
}
.social-network li:hover .fa-pinterest-square {
  color: #EA4B49; 
}

.logo-alt{
    margin-top: 7px;
}
@media screen and (max-width: 768px) {
    .logo-exibir {
        display: none;
    }

}